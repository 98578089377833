import Vue from "vue";
import VueRouter from "vue-router";

import { Trans } from "@/plugins/translation";

function load(pathToPageComponent) {
  return () => import(`@/views/${pathToPageComponent}`);
}

Vue.use(VueRouter);

const routePath = [
  {
    path: "/search-results/",
    name: "SearchResult",
    meta: {
      layout: "default",
    },
    component: load("SearchResult"),
  },
  {
    path: "",
    name: "Home",
    meta: {
      layout: "default",
    },
    component: load("Home"),
  },
  {
    path: "workarea",
    name: "TherapicArea",
    meta: {
      layout: "default",
    },
    component: load("TherapicArea"),
  },
  {
    path: "workarea/oncology",
    name: "Oncology",
    meta: {
      layout: "default",
    },
    component: load("Oncology"),
  },
  {
    path: "workarea/oncology/:slug",
    name: "OncologyDetail",
    meta: {
      layout: "default",
    },
    component: load("OncologyDetail"),
  },
  {
    path: "workarea/cardiovascular_and_metabolic_diseases",
    name: "Cardiology",
    meta: {
      layout: "default",
    },
    component: load("Cardiology"),
  },
  {
    path: "workarea/cardiovascular_and_metabolic_diseases/:slug",
    name: "CardiologyDetail",
    meta: {
      layout: "default",
    },
    component: load("CardiologyDetail"),
  },
  {
    path: "workarea/immunology",
    name: "Immunology",
    meta: {
      layout: "default",
    },
    component: load("Immunology"),
  },
  {
    path: "workarea/immunology/:slug",
    name: "ImmunologyDetail",
    meta: {
      layout: "default",
    },
    component: load("ImmunologyDetail"),
  },
  {
    path: "workarea/rare-diseases",
    name: "RareDiseases",
    meta: {
      layout: "default",
    },
    component: load("RareDiseases"),
  },
  {
    path: "workarea/respiratory",
    name: "Respiratory",
    meta: {
      layout: "default",
    },
    component: load("Respiratory"),
  },
  {
    path: "workarea/respiratory/:slug",
    name: "RespiratoryDetail",
    meta: {
      layout: "default",
    },
    component: load("RespiratoryDetail"),
  },
  {
    path: "media/press",
    name: "Press",
    meta: {
      layout: "default",
    },
    component: load("Press"),
  },
  {
    path: "media/press/:id",
    name: "PressDetail",
    meta: {
      layout: "default",
    },
    component: load("medialib/PressDetail"),
  },
  {
    path: "media/spec-press",
    name: "SpecPress",
    meta: {
      layout: "default",
    },
    component: load("SpecPress"),
  },
  {
    path: "media/spec-press/:id",
    name: "SpecPressDetail",
    meta: {
      layout: "default",
    },
    component: load("medialib/SpecPressDetail"),
  },
  // {
  //   path: "media/about-us",
  //   name: "MediaAboutUs",
  //   meta: {
  //     layout: "default",
  //   },
  //   component: load("MediaAboutUs"),
  // },
  // {
  //   path: "media/about-us/:id",
  //   name: "MediaDetail",
  //   meta: {
  //     layout: "default",
  //   },
  //   component: load("medialib/MediaDetail"),
  // },
  {
    path: "patients/",
    name: "PatientMain",
    meta: {
      layout: "default",
    },
    component: load("PatientMain"),
  },
  {
    path: "patients/:slug",
    name: "PatientDetail",
    meta: {
      layout: "default",
    },
    component: load("PatientDetail"),
  },
  {
    path: "contact",
    name: "Contact",
    meta: {
      layout: "default",
    },
    component: load("Contact"),
  },
  {
    path: "confidentiality",
    name: "Confidentiality",
    meta: {
      layout: "default",
    },
    component: load("Confidentiality"),
  },
  {
    path: "legal-information",
    name: "LegalInformation",
    meta: {
      layout: "default",
    },
    component: load("LegalInformation"),
  },
  {
    path: "astrazeneca_in_the_world",
    name: "AboutCompany",
    meta: {
      layout: "default",
    },
    component: load("AboutCompany"),
  },
  {
    path: "astrazeneca_in_kazakhstan",
    name: "AzInKz",
    meta: {
      layout: "default",
    },
    component: load("AzInKz"),
  },
  {
    path: "astrazeneca_in_kazakhstan/letter_from_the_chairman",
    name: "Lftc",
    meta: {
      layout: "default",
    },
    component: load("Lftc"),
  },
  {
    path: "astrazeneca_in_kazakhstan/preparations",
    name: "Drugs",
    meta: {
      layout: "default",
    },
    component: load("Drugs"),
  },
  {
    path: "astrazeneca_in_kazakhstan/social_responsibilty",
    name: "SocialResponsibility",
    meta: {
      layout: "default",
    },
    component: load("social-responsibility/SocialResponsibility"),
  },
  {
    path: "astrazeneca_in_kazakhstan/social_responsibilty/safety-and-labor-protection",
    name: "Safety",
    meta: {
      layout: "default",
    },
    component: load("social-responsibility/Safety"),
  },
  {
    path: "astrazeneca_in_kazakhstan/social_responsibilty/ethical-standards",
    name: "EsteticStandarts",
    meta: {
      layout: "default",
    },
    component: load("social-responsibility/EsteticStandarts"),
  },
  {
    path: "astrazeneca_in_kazakhstan/social_responsibilty/social-projects",
    name: "SocialProjects",
    meta: {
      layout: "default",
    },
    component: load("social-responsibility/SocialProjects"),
  },
  {
    path: "astrazeneca_in_kazakhstan/career",
    name: "Carier",
    meta: {
      layout: "default",
    },
    component: load("Carier"),
  },
];

const routes = [
  {
    path: "/",
    component: {
      template: "<router-view/>",
    },
    children: [...routePath].map(function (el) {
      return {
        ...el,
        beforeEnter: Trans.routeMiddleware,
        name: el.name,
      };
    }),
  },

  // Страницы с явно указанными локалями
  {
    path: `/:locale(${process.env.VUE_APP_I18N_SUPPORTED_LOCALE.replace(
      /,/g,
      "|"
    )})/`,
    component: {
      template: "<router-view/>",
    },
    children: [
      ...routePath
        .map(function (el) {
          return {
            ...el,
            beforeEnter: Trans.routeMiddleware,
            name: el.name + "Loc",
          };
        })
        .concat({
          path: `*`,
          name: "404Loc",
          beforeEnter: Trans.routeMiddleware,
          component: load("404"),
          meta: {
            layout: "default",
          },
        }),
    ],
  },
  {
    path: `/*`,
    name: "404",
    beforeEnter: Trans.routeMiddleware,
    component: load("404"),
    meta: {
      layout: "default",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (
        to.hash.replace("#", "") &&
        document.getElementById(to.hash.replace("#", ""))
      ) {
        return {
          selector: to.hash,
        };
      } else {
        if (to.path !== from.path) {
          return { x: 0, y: 0 };
        }
      }
    }
  },
  routes,
});

export default router;
